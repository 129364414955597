<template>
    <div>
        <div>
            <b-checkbox switch size="sm" v-model="with_ranks">Avec classements intermédiaires</b-checkbox>
            <b-checkbox switch size="sm" v-model="with_subtotals">Avec sous-totaux</b-checkbox>
        </div>
        <div>
            Tri:
            <b-radio-group v-model="sortType" size="sm">
                <b-form-radio value="rank">Classement</b-form-radio>
                <b-form-radio value="alphabetical">Alphabétique</b-form-radio>
            </b-radio-group>
        </div>
        <div v-if="sortType=='rank' && false">
            <b-select v-model="sortRankType" size="sm">
                <b-option v-if="event.EVENT.hasCompulsories" value="TotalCompulsory">Total figures</b-option>
                <b-option v-if="event.EVENT.hasCompulsories" value="Compulsory_1">Figure 1</b-option>
            </b-select>
        </div>
        <div ref="render" class="results-render" style="display:flex">
            <table style="font-size: 0.8rem;">
                <thead>
                    <tr class="grid-header">
                        <th colspan="2" rowspan="2">Compétiteur</th>
                        <th v-for="event in events" :key="event.name" :colspan="event.colspan">{{ event.name }}</th>
                        <th v-if="precisionCol.shown" :colspan="precisionCol.colspan">{{ precisionCol.text }}</th>
                        <th v-if="balletCol.shown" :colspan="balletCol.colspan">{{ balletCol.text }}</th>
                        <th v-if="precisionCol.shown && balletCol.shown" :rowspan="(with_subtotals || with_ranks) ? 2 : 1">Total</th>
                        <th v-if="precisionCol.shown && balletCol.shown" :rowspan="(with_subtotals || with_ranks) ? 2 : 1">Rg.</th>
                    </tr>

                    <tr class="grid-header">
                        <th v-for="event in EVENTS" :key="event.name + '_precision'">
                            {{event.name}}
                        </th>
                        <th v-if="!event.EVENT.hasCompulsories" colspan="2">Compétiteur</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.1</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.2</th>
                        <th v-if="event.EVENT.hasCompulsories">Fig.3</th>
                        <th v-if="event.EVENT.hasCompulsories && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasCompulsories && with_ranks == true ">Rg.</th>
                        <th v-if="event.EVENT.hasRoutine">Exec.</th>
                        <th v-if="event.EVENT.hasRoutine">Cont.</th>
                        <th v-if="event.EVENT.hasRoutine && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasRoutine && with_ranks == true ">Rg.</th>
                        <th v-if="event.EVENT.hasBallet">Choré.</th>
                        <th v-if="event.EVENT.hasBallet">Exec.</th>
                        <th v-if="event.EVENT.hasBallet && with_subtotals">Total</th>
                        <th v-if="event.EVENT.hasBallet && with_ranks == true ">Rg.</th>
                        <th>Total</th>
                        <th>Rg.</th>
                    </tr>
                </thead>
                <!--<div style="max-height:300px; overflow-y:scroll;">-->
                <tbody>
                    <tr v-for="row in rows" :key="row.id" :class="{'even': (row.index % 2 == 0), 'odd': (row.index %2 !=0) }">
                        <td>{{ row.index }}</td>
                        <td>{{ row.COMPETITOR.name }}</td>
                        <td v-for="compulsory in row.compulsories" :key="compulsory.id">{{ arrondir(compulsory.noteValue) }}</td>
                        <td v-if="event.EVENT.hasCompulsories && with_subtotals">{{ arrondir(row.totalCompulsories.noteValue) }}</td>
                        <td v-if="event.EVENT.hasCompulsories && with_ranks==true">{{ row.totalCompulsories.rank}}</td>
                        <td v-if="event.EVENT.hasRoutine">{{ arrondir(row.routine.execution) }}</td>
                        <td v-if="event.EVENT.hasRoutine">{{ arrondir(row.routine.content) }}</td>
                        <td v-if="event.EVENT.hasRoutine && with_subtotals==true">{{ arrondir(row.routine.note) }}</td>
                        <td v-if="event.EVENT.hasRoutine && with_ranks==true">{{ row.routine.rank }}</td>
                        <td v-if="event.EVENT.hasBallet">{{ arrondir(row.ballet.choreo) }}</td>
                        <td v-if="event.EVENT.hasBallet">{{ arrondir(row.ballet.execution) }}</td>
                        <td v-if="event.EVENT.hasBallet && with_subtotals==true">{{ arrondir(row.ballet.note) }}</td>
                        <td v-if="event.EVENT.hasBallet && with_ranks==true">{{ row.ballet.rank }}</td>
                        <td>{{ arrondir(row.note) }}</td>
                        <td>{{ row.rank }}</td>
                    </tr>
                </tbody>
                <!--</div>-->
            </table>
        </div>
    </div>
</template>

<script>

import { /*RST_EVENT,*/ RST_REF_EVENT, RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE, RST_BALLET_NOTE /*RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE*/ } from "@/LocalDB";

export default {
    data(){
        return {
            with_ranks: this.includeSubRanks,
            with_subtotals: this.includeSubTotals,
            typeDeTri: this.sortOrder,
            sortRankType: 'overall',
            rows: [],
            events: [],
            precisionCol: { shown: false },
            balletCol: { shown: false },
        };
    },
    props: {
            competition: { type : Object, required: true, },
            round_number: { type: Number, required: true, },
            level: { type : Object, required: true, },
            category : { type : Object, required: true, },
            competitor: { type: Object, default: null},
            includeJudgeDetails: {type: Boolean, default: false},
            includeSubTotals: { type: Boolean, default: true},
            includeSubRanks: { type: Boolean, default: true},
            sortOrder: { type: String, default: 'rank'}
        },
    computed:{
        withRanks:{
            get: function()  { return this.with_ranks; },
            set: function(newValue) { this.with_ranks = newValue; this.refresh();}
        },
        sortType :{
            get: function() { return this.typeDeTri; },
            set: function(newValue) { this.typeDeTri = newValue; this.refresh(); }
        },
        colspanOptionsCount(){
            var ret = (this.with_ranks ? 1 : 0)
                    + (this.with_subtotals ? 1 : 0);
            return ret;
        }
    },
    methods:{
        refresh(){
            console.log('refresh', this.round_number, this.category);

            RST_REF_EVENT.query()
                            .where("competition_id", this.competition.id)
                            .where("round_number", this.round_number)
                            .where("level", this.level.level)
                            .where("category", this.category.category)
                            .get()
                            .map(re => {
                                re.hasPrecision = (re.EVENT.hasCompulsories || re.EVENT.hasRoutine);
                                re.hasBallet = re.EVENT.hasBallet;
                                /*var evt = RST_EVENT.query()
                                .where("competition_id", this.competition.id)
                                .where("round_number", this.round_number)
                                .where("level", this.level.level)
                                .where("category", this.category.category)
                                .where("event", re.event)
                                .first();*/
                                
                                return re;
                            });

            var sortCol = "";
            switch(this.typeDeTri){
                case "execution":
                    sortCol = 'effective_order';
                    break;
                case "rank":
                    sortCol = "rank";
                    break;
/*              case "alphabetical":
                    Ce cas est traité à la fin car on ne peut pas effectuer le tri sur une colonne précise pour l'ordre alphabétique.
*/
            }
            var retRows = this.event._EFFECTIVE_COMPETITORS
                        .orderBy(sortCol)
                        .get()
                        .map((p,i) => { 
                            var c = RST_COMPULSORY_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.compulsory_number != null)
                                        .where(c => c.judge_id == null)
                                        .orderBy("compulsory_number")
                                        .get();
                            var tc = RST_COMPULSORY_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.compulsory_number == null)
                                        .where(c => c.judge_id == null)
                                        .first();
                            var r = RST_ROUTINE_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.judge_id == null)
                                        .first();
                            var b = RST_BALLET_NOTE.query()
                                        .where("competition_id", this.event.competition_id)
                                        .where("round_number", this.event.round_number)
                                        .where("level", this.event.level)
                                        .where("category", this.event.category)
                                        .where("event", this.event.event)
                                        .where("competitor_id", p.competitor_id)
                                        .where(c => c.judge_id == null)
                                        .first();
                            p.index = i + 1;
                            p.compulsories = c;
                            p.totalCompulsories = tc;
                            p.routine = r;
                            p.ballet = b;
                            p.competitor_name = p.COMPETITOR.name;
                            p.competitor_shortname = p.COMPETITOR.shortname;
                            return p;
                            }
                        );
                        console.log('refresh (data)', this.rows);

            var needSort = false;
            switch(this.typeDeTri){
                case "alphabetical":
                    retRows.sort((a,b) => a.COMPETITOR.name.localeCompare(b.COMPETITOR.name));
                    needSort = true;
                    break;
            }

            if(needSort)
                retRows.map((r,i) => { r.index = i + 1; return r});

            this.rows = retRows;
        }
    },
    mounted(){
        this.typeDeTri = 'rank';
        this.refresh();
    },
    watch:{
        category: function(){
            this.refresh();
        },
        round_number: function(){
            this.refresh();
        },
        includeSubRanks: function(newVal){
            this.with_ranks = newVal;
        },
        includeSubTotals: function(newVal){
            this.with_subtotals = newVal;
        },
        sortOrder: function(newVal){
            this.typeDeTri = newVal;
        }
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results-render > table {
        border-collapse: collapse;
        border: solid 2px var(--ffvlOrangePicto);
    }
    .results-render > table  td, .results-render > table th{
        border: solid 1px var(--ffvlOrangePicto);
        text-align: center;
    }
    
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

    .even{ background-color: beige;}
    .odd{ background-color: #cdcdfd;}

    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-render{
            orientation: landscape;
        }
    }
</style>
